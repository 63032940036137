import React from 'react'
import Helmet from 'react-helmet'
import { ToastContainer } from 'react-toastify'

import favicon from '../images/favicon.ico'

import { getPageTitle, getPageSlug } from '@theme/utils/stringManipulations'

import siteConfig from '@theme/utils/siteConfig'

import Header from '../components/Header'
import Footer from '../components/Footer'
// import PageTitle from "../PageTitle"

const slugToDescriptionMap = {
  home: 'Made from the younger vines of Harlan Estate, BOND, and Promontory, The Mascot shares a unique glimpse into the evolution of our winegrowing estates.',
  wine: 'The Mascot is a Napa Valley Cabernet Sauvignon made from distinct hillside vineyards; specifically, the younger vines of BOND, Harlan Estate, and Promontory.',
  faqs: 'Learn how to purchase The Mascot wine, how to join the allocation, when to drink The Mascot, and more. ',
  'allocation-request':
    'Each year we offer our new vintage of The Mascot at pre-release pricing to members of our private allocation list. To request an invitation to our annual offering, kindly submit your information below.',
  'shipping-policy':
    'To ensure that you are able to order wine for direct shipment from our website, please consult the specific state requirements for your state here.',
  'privacy-and-terms':
    'Protecting your privacy is important to us. This policy describes how The Mascot collects, safeguards and uses information that you provide to us.',
  'bottle-verification': 'Please enter your Bottle ID to authenticate.',
}

const Layout = (props) => {
  const { location, children, title } = props
  const pageSlug = getPageSlug(location.pathname)

  var pageTitle = getPageTitle(location.pathname)
  var siteTitle = siteConfig?.metadata?.title

  if (pageSlug == 'home') {
    pageTitle = `Napa Valley Cabernet Sauvignon`
  } else if (pageSlug == 'wine') {
    siteTitle = `${siteConfig?.metadata?.title} Wine`
    pageTitle = `Napa Valley Cabernet Sauvignon`
  } else if (pageSlug == 'allocation-request') {
    siteTitle = `${siteConfig?.metadata?.title} Wine`
    pageTitle = `Join Allocation`
  }

  // console.log('pageSlug', pageSlug)
  // console.log('pageTitle', pageTitle)

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>
          {siteTitle} | {pageTitle}
        </title>
        <meta name="description" content={slugToDescriptionMap[pageSlug] || ''} />
        <link rel="icon" href={favicon} />
        <body />
      </Helmet>

      {/* <ErrorBoundary> */}
      {/* <GeneralModalError /> */}
      <Header {...props} />

      <ToastContainer
        position="top-left"
        autoClose={3000}
        hideProgressBar
        icon={false}
        style={{ width: '500px', maxWidth: '500px' }}
      />

      <main className="content-wrapper" id={pageSlug}>
        <div className="content-child w-full overflow-hidden center">
          {/* <PageTitle title={getPageTitle(location.pathname)} /> */}
          {children}
        </div>
      </main>

      <Footer {...props} />
      {/* </ErrorBoundary> */}
    </>
  )
}

export default Layout
